import React from 'react'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

import { RequireAuth } from '@hs-baumappe/web-auth'

import { AuthCallback } from '../apollo/auth/AuthCallback'

import { HideBehindFeatureFlag, LDProvider } from './featureFlagging'
import { GraphQlTest } from './graphql-test'
import { WeatherTestMeteoblue, WeatherTestTomorrowIo } from './weather'

const StyledApp = styled.div`
	// Your style here
`
interface AppProps {
	basename?: string
}

// TODO: This file is currently exposed via module federation.
// It might make more sense to create a separate file for the module federation entry point.
// We could put the required wrappers (like BrowserRouter) there, and keep this file clean(er).

const App: React.FC<AppProps> = ({ basename = '/construction-diary' }) => {
	return (
		<BrowserRouter basename={basename}>
			<LDProvider>
				<StyledApp>
					{/* START: routes */}
					{/* These routes and navigation have been generated for you */}
					{/* Feel free to move and update them to fit your needs */}
					<h1>
						<span role="img" aria-label="Construction Diary Walking Skeleton">
							🚧📔🚶💀
						</span>
					</h1>
					<br />
					<hr />
					<br />
					<div role="navigation">
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/page-2">Page 2</Link>
							</li>
							<li>
								<Link to="/projects">Project List</Link>
							</li>
							<li>
								<Link to="/weather/tomorrow">
									<span role="img" aria-label="weather">
										🌤️⛈️ (tomorrow.io)
									</span>
								</Link>
							</li>
							<li>
								<Link to="/weather/meteoblue">
									<span role="img" aria-label="weather">
										🌤️⛈️ (meteoblue)
									</span>
								</Link>
							</li>
							<HideBehindFeatureFlag flag="bmplanWalkingSkeletonTest">
								<li>
									<Link to="/page-2">Page 3 - I am behind feature flag</Link>
								</li>
							</HideBehindFeatureFlag>
						</ul>
					</div>
					<Routes>
						<Route path="auth/callback" element={<AuthCallback />} />
						<Route
							path="/"
							element={
								<div>
									This is the generated root route. <Link to="/page-2">Click here for page 2.</Link>
								</div>
							}
						/>
						<Route
							path="/page-2"
							element={
								<div>
									<Link to="/">Click here to go back to root page.</Link>
								</div>
							}
						/>
						<Route
							path="/projects"
							element={
								<RequireAuth>
									<GraphQlTest />
								</RequireAuth>
							}
						/>
						<Route path="/weather/tomorrow" element={<WeatherTestTomorrowIo />} />
						<Route path="/weather/meteoblue" element={<WeatherTestMeteoblue />} />
					</Routes>
					{/* END: routes */}
				</StyledApp>
			</LDProvider>
		</BrowserRouter>
	)
}

export default App
