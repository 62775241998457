import React, { FormEvent, useState } from 'react'

import { FormContainer, WeatherCard, WeatherCardContainer, WeatherContainer } from './index'

type WeatherData = {
	day: string
	temp_mean: number
	temp_min: number
	temp_max: number
}

type LocationData = {
	location: string
	lat: number
	lon: number
}

type WeatherResponse = {
	geoLocation: LocationData
	weatherData: WeatherData[]
}

type RawWeatherResponse = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any
}

export const WeatherTestMeteoblue: React.FC = () => {
	const [response, setResponse] = useState<WeatherResponse | undefined>(undefined)
	const [rawResponse, setRawResponse] = useState<RawWeatherResponse | undefined>(undefined)
	const [loading, setLoading] = useState<boolean>(false)
	const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoading(true)
		try {
			const formData = new FormData(event.currentTarget)
			const locationInput = formData.get('locationInput') as string
			const response = await fetch(`/api/v1/weather/meteoblue?addressLine=${encodeURI(locationInput)}`)
			const { geoLocation, data } = await response.json()
			const weatherData: WeatherData[] = data?.data_day?.time?.map((day: string, index: number) => {
				return {
					day,
					temp_mean: data.data_day?.temperature_mean[index],
					temp_min: data.data_day?.temperature_min[index],
					temp_max: data.data_day?.temperature_max[index],
				}
			})
			const weatherResponse: WeatherResponse = { geoLocation, weatherData }
			const rawWeatherResponse: RawWeatherResponse = { data }
			setRawResponse(rawWeatherResponse)
			setResponse(weatherResponse)
		} finally {
			setLoading(false)
		}
	}

	return (
		<WeatherContainer>
			<h1>Meteoblue</h1>
			<FormContainer onSubmit={onSubmit}>
				<label htmlFor="locationInput">Location</label>
				<input id="locationInput" type="text" name="locationInput" />
				<button type="submit">Get Weather</button>
			</FormContainer>

			{loading && <span>Loading...</span>}
			{!loading && response && (
				<>
					<span>The weather in</span>
					<span>
						<b>{response.geoLocation?.location}</b>
					</span>
					<span>
						Geo: {response.geoLocation?.lat} {response.geoLocation.lon}
					</span>
					<span>for next week and the past 3 days:</span>
					<WeatherCardContainer>
						{response.weatherData.map((data: WeatherData, index: number) => {
							return (
								<WeatherCard key={index}>
									<h2>{new Date(data.day).toDateString()}</h2>
									<h3>{data.temp_mean} ºC</h3>
									<h3>
										Min: {data.temp_min} ºC / Max: {data.temp_max} ºC
									</h3>
								</WeatherCard>
							)
						})}
					</WeatherCardContainer>
					<div>
						<h3>Available Fields in this version of the API:</h3>
						{Object.keys(rawResponse?.data?.data_day).map((value: string, index: number) => (
							<div key={index}>{value}</div>
						))}
					</div>
				</>
			)}
		</WeatherContainer>
	)
}
