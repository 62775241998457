import { from, fromPromise } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { graphql } from '../../generated/gql'
import { RetrieveTokenMutation } from '../../generated/gql/graphql'
import client from '../client'
import tokenStorage from '../tokenStorage'

// gql must be declared on root level for graphql-codegen to work
const retrieveTokenMutation = graphql(`
	mutation RetrieveToken($input: RetrieveTokensInput!) {
		retrieveTokens(input: $input) {
			refreshToken
			accessToken
		}
	}
`)

// function to retrieve new tokens using the refreshTokeh via graphql
async function retrieveTokens(refreshToken: string): Promise<RetrieveTokenMutation['retrieveTokens']> {
	const { data } = await client.mutate({
		mutation: retrieveTokenMutation,
		variables: {
			input: {
				refreshToken,
			},
		},
	})

	if (!data) throw new Error('Authentication error. Refresh of access_token failed')

	tokenStorage.setToken({
		accessToken: data.retrieveTokens.accessToken,
		refreshToken: data.retrieveTokens.refreshToken,
	})

	return data.retrieveTokens
}

const injectAuthHeader = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = tokenStorage.getToken()
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token.accessToken ? `Bearer ${token.accessToken}` : '',
		},
	}
})

const onUnauthenticatedError = onError(({ graphQLErrors, operation, forward }) => {
	const unauthenticatedError = graphQLErrors?.find((error) => error.extensions?.code === 'UNAUTHENTICATED')
	if (unauthenticatedError) {
		const { refreshToken } = tokenStorage.getToken()

		return fromPromise(
			retrieveTokens(refreshToken || '').catch(() => {
				tokenStorage.removeToken()
				client.resetStore()
			})
		)
			.filter((value) => Boolean(value))
			.flatMap((tokens) => {
				const oldHeaders = operation.getContext().headers
				// modify the operation context with a new token
				operation.setContext({
					headers: {
						...oldHeaders,
						authorization: tokens?.accessToken ? `Bearer ${tokens?.accessToken}` : '',
					},
				})

				return forward(operation)
			})
	}
})

export const authLink = from([injectAuthHeader, onUnauthenticatedError])
