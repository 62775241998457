import { useQuery } from '@apollo/client'
import React from 'react'

import { graphql } from '../generated/gql'

const allProjects = graphql(`
	query allProjects($page: Int) {
		projects(page: $page) {
			projects {
				id
				city
				street
				postCode
				addressLine
			}
		}
	}
`)

export const GraphQlTest: React.FC = () => {
	const { data, networkStatus, error } = useQuery(allProjects, { variables: { page: 1 } })

	console.log({ data, networkStatus, error })
	return (
		<ul>
			{data?.projects?.projects?.map((project, index) => (
				<li key={index}>
					{project.id}, {project.postCode} {project.city}, {project.street}, {project.addressLine}
				</li>
			))}
		</ul>
	)
}
