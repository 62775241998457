import { InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { ViewerQuery } from '@hs-baumappe/web-auth'

export const languageLink = setContext((_, context) => {
	const cache: InMemoryCache = context.cache
	const headers = context.headers

	const viewerQuery = cache.readQuery({ query: ViewerQuery })
	const language = viewerQuery?.viewer.preferredLanguageCode.toLowerCase()

	if (!language) {
		return { headers }
	}

	return {
		headers: {
			...headers,
			'Accept-Language': language,
		},
	}
})
