/**
 * Note: All config settings *must* begin with NX_PUBLIC_
 */
export interface ApplicationConfiguration {
	LAUNCH_DARKLY_CLIENT_SIDE_ID: string
	LOGIN_URL: string
	SERVICE_DESK_EMAIL_ADDRESS: string
	GRAPHQL_SERVER_URL: string
}

// shim to tell typescript that window.config is a thing
declare global {
	interface Window {
		config: ApplicationConfiguration
	}
}

// merge local config with window.config
const devConfig: ApplicationConfiguration = {
	// VAR_NAME: process.env.NX_PUBLIC_VAR_NAME ?? 'fallback value when there is no env var',
	LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.NX_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
	LOGIN_URL: process.env.NX_PUBLIC_LOGIN_URL ?? '',
	SERVICE_DESK_EMAIL_ADDRESS: 'servicedesk@heinrich-schmid.de',
	GRAPHQL_SERVER_URL: process.env.NX_PUBLIC_GRAPHQL_SERVER_URL ?? '',
}

// export merged config so it can be used as if it were a regular importable property
export const config = { ...devConfig, ...window.config }
