import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { config } from '../../config'
import tokenStorage from '../tokenStorage'

import { getLoginRedirectCache, removeLoginRedirectCache } from './loginRedirectCache'

// A15 component to handle the authentication callback (redirect back from the authentication server)
export const AuthCallback: React.FC = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const accessToken = searchParams.get('accessToken')
	const refreshToken = searchParams.get('refreshToken')
	const authErrorOccurred = searchParams.has('error')
	const emptyEmailErrorOccurred = searchParams.has('nullEmail')

	useEffect(() => {
		if (!accessToken || !refreshToken) {
			return
		}

		tokenStorage.setToken({ accessToken, refreshToken })

		const redirectUrl = getLoginRedirectCache()
		if (redirectUrl) {
			removeLoginRedirectCache()
			window.location.assign(redirectUrl)

			return
		}

		navigate('/')
	}, [accessToken, navigate, refreshToken])

	function handleTryAgainButtonClick() {
		tokenStorage.removeToken()

		window.location.assign(config.LOGIN_URL)
	}

	return authErrorOccurred || emptyEmailErrorOccurred ? (
		<>
			{emptyEmailErrorOccurred ? (
				<a href={`mailto:${config.SERVICE_DESK_EMAIL_ADDRESS}?subject=authCallback.serviceDeskEmailSubject`}>
					Contact service desk
				</a>
			) : (
				searchParams.get('error')
			)}

			<button onClick={handleTryAgainButtonClick}>Try again</button>
		</>
	) : (
		'loading...'
	)
}
