const LOGIN_REDIRECT_CACHE_KEY = '@loginRedirect'

export function setLoginRedirectCache(value: string): void {
	localStorage.setItem(LOGIN_REDIRECT_CACHE_KEY, value)
}

export function getLoginRedirectCache(): string | undefined {
	return localStorage.getItem(LOGIN_REDIRECT_CACHE_KEY) ?? undefined
}

export function removeLoginRedirectCache(): void {
	localStorage.removeItem(LOGIN_REDIRECT_CACHE_KEY)
}
