import { ApolloProvider } from '@apollo/client'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import { ViewerProvider } from './apollo/auth/ViewerProvider'
import client from './apollo/client'
import App from './app/app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<StrictMode>
		<ApolloProvider client={client}>
			<ViewerProvider>
				<App basename="/" />
			</ViewerProvider>
		</ApolloProvider>
	</StrictMode>
)
