import React, { FormEvent, useState } from 'react'
import styled from 'styled-components'

export const WeatherContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 10px;
`
export const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
	margin-bottom: 20px;
`

export const WeatherCardContainer = styled.form`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 20px;
`

export const WeatherCard = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
	border: 1px solid black;
	padding: 20px;
	margin-right: 10px;
`

const weatherCode: Record<number, string> = {
	0: 'Unknown',
	1000: 'Clear, Sunny',
	1100: 'Mostly Clear',
	1101: 'Partly Cloudy',
	1102: 'Mostly Cloudy',
	1001: 'Cloudy',
	2000: 'Fog',
	2100: 'Light Fog',
	4000: 'Drizzle',
	4001: 'Rain',
	4200: 'Light Rain',
	4201: 'Heavy Rain',
	5000: 'Snow',
	5001: 'Flurries',
	5100: 'Light Snow',
	5101: 'Heavy Snow',
	6000: 'Freezing Drizzle',
	6001: 'Freezing Rain',
	6200: 'Light Freezing Rain',
	6201: 'Heavy Freezing Rain',
	7000: 'Ice Pellets',
	7101: 'Heavy Ice Pellets',
	7102: 'Light Ice Pellets',
	8000: 'Thunderstorm',
}

export const WeatherTestTomorrowIo: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [response, setResponse] = useState<any>(undefined)
	const [loading, setLoading] = useState<boolean>(false)

	const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoading(true)
		try {
			const formData = new FormData(event.currentTarget)
			const locationInput = formData.get('locationInput') as string
			const response = await fetch(`/api/v1/weather/tomorrow-io?addressLine=${encodeURI(locationInput)}`)
			setResponse(await response.json())
		} finally {
			setLoading(false)
		}
	}

	return (
		<WeatherContainer>
			<h1>Tomorrow.io</h1>
			<FormContainer onSubmit={onSubmit}>
				<label htmlFor="locationInput">Location</label>
				<input id="locationInput" type="text" name="locationInput" />
				<button type="submit">Get Weather</button>
			</FormContainer>

			{loading && <span>Loading...</span>}
			{!loading && response && (
				<>
					<span>The weather in</span>
					<span>
						<b>{response.geoLocation?.name}</b>
					</span>
					<span>
						Geo: {response.geoLocation?.lat} {response.geoLocation.lon}
					</span>
					<span>for next week:</span>
					<WeatherCardContainer>
						{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
						{response.data.map((day: any, index: number) => {
							return (
								<WeatherCard key={index}>
									<h2>{new Date(day.time).toDateString()}</h2>
									<h3>{weatherCode[day.values.weatherCodeMin]}</h3>
									<h3>
										Min: {day.values.temperatureMin} ºC / Max: {day.values.temperatureMax} ºC
									</h3>
								</WeatherCard>
							)
						})}
					</WeatherCardContainer>
					<div>
						<h3>Available Fields in this version of the API:</h3>
						{Object.keys(response.data[0].values).map((value: string, index: number) => (
							<div key={index}>{value}</div>
						))}
					</div>
				</>
			)}
		</WeatherContainer>
	)
}
