import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'

import { config } from '../config'

import { authLink, languageLink } from './links'

// setup the (connection-)link with http
const http = createHttpLink({
	uri: config.GRAPHQL_SERVER_URL,
	fetch,
	headers: { 'Apollo-Require-Preflight': 'true' },
})

// This configuration is based on A15 code, it can possibly be simplified for construction diary
const client = new ApolloClient({
	cache: new InMemoryCache({
		typePolicies: {
			Project: {
				fields: {
					scopes: {
						merge: true,
					},
				},
			},
		},
	}),
	link: from([authLink, languageLink, http]),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
		},
	},
	connectToDevTools: process.env.NODE_ENV === 'development',
})

export default client
