import { LDContext, LDProvider as OriginalLDProvider } from 'launchdarkly-react-client-sdk'
import { PropsWithChildren } from 'react'

import { config } from '../../config'

export const context: LDContext = {
	key: 'anonymous',
	anonymous: true,
}

export const LDProvider: React.FC<PropsWithChildren> = ({ children }) => (
	<OriginalLDProvider clientSideID={config.LAUNCH_DARKLY_CLIENT_SIDE_ID} context={context}>
		{children}
	</OriginalLDProvider>
)
