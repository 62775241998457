import { useQuery } from '@apollo/client'
import { PropsWithChildren, useCallback } from 'react'
import { Navigate, createPath } from 'react-router-dom'

import { ViewerProvider as ViewerProviderBase, ViewerQuery } from '@hs-baumappe/web-auth'

import { config } from '../../config'
import tokenStorage from '../tokenStorage'

import { setLoginRedirectCache } from './loginRedirectCache'

const errorCallbackPath = createPath({
	pathname: 'auth/callback',
	search: new URLSearchParams({ error: 'true' }).toString(),
})

// A15 component to redirect to login page if user is not authenticated
export const ViewerProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const { accessToken } = tokenStorage.getToken()
	const { data, loading, error } = useQuery(ViewerQuery, {
		skip: !accessToken,
	})

	const handleRequireAuth = useCallback(() => {
		if (accessToken) {
			return
		}

		setLoginRedirectCache(window.location.href)
		window.location.assign(config.LOGIN_URL)
	}, [accessToken])

	if (error) {
		return <Navigate to={errorCallbackPath} />
	}

	if (loading) {
		return (
			<div style={{ height: '100vh' }} data-testid="loading">
				Loading...
			</div>
		)
	}

	return (
		<ViewerProviderBase viewer={data?.viewer} onRequireAuth={handleRequireAuth}>
			{children}
		</ViewerProviderBase>
	)
}
