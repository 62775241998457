import { useFlags } from 'launchdarkly-react-client-sdk'
import { PropsWithChildren } from 'react'

interface HideBehindFeatureFlagProps {
	flag: string
}

export const HideBehindFeatureFlag: React.FC<PropsWithChildren<HideBehindFeatureFlagProps>> = ({ children, flag }) => {
	const flags = useFlags()
	if (flags[flag]) {
		return children
	} else {
		return null
	}
}
